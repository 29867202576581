<template>
  <div ref="container" class="m-slider">
    <div class="games">
      <slot />
    </div>
    <slot name="after" />
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    rows?: number
  }>(),
  {
    rows: 2,
  },
)

const rows = computed(() => `repeat(${props.rows}, 130px)`)

const container = useTemplateRef<HTMLDivElement>('container')

defineExpose({
  scrollToStart: () => {
    container.value?.scrollTo({ left: 0, behavior: 'smooth' })
  },
})
</script>

<style scoped>
.m-slider {
  scrollbar-color: transparent transparent;

  overflow: auto hidden;
  display: flex;

  padding: 0 var(--spacing-200);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.games {
  display: grid;
  grid-auto-columns: 140px;
  grid-auto-flow: column;
  grid-template-rows: v-bind(rows);
  gap: var(--spacing-200) var(--spacing-125);
}
</style>
