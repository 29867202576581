<template>
  <MStSection
    class="m-providers-slider"
    link="/casino/providers"
    :size="size"
    :title-icon="withTitleIcon ? 'casino-games' : undefined"
    :title="t('categories.providers')"
    :with-chevron="withChevron"
    :header-data-t="sectionDataT"
    :button-text="t('casino.all')"
    :button-counter="providers?.paging.total"
  >
    <StSlider class="providers-list" disable-arrows>
      <StSliderItem
        v-for="provider of providers?.data"
        :key="provider.id"
        class="provider-card-item"
      >
        <ProviderCard :provider="provider" mobile />
      </StSliderItem>
    </StSlider>
  </MStSection>
</template>

<script lang="ts" setup>
import MStSection from '@st/ui/components/StSection/MStSection.vue'
import { type Props as StSectionHeaderProps } from '@st/ui/components/StSectionHeader/StSectionHeader.vue'

type Props = Pick<StSectionHeaderProps, 'size'> & {
  withChevron?: boolean
  withTitleIcon?: boolean
}

const { t, locale } = useI18n()

const { size, withChevron = true, withTitleIcon = true } = defineProps<Props>()

const { data: providers } = useStFetch('/casino/provider/find', {
  method: 'post',
  body: {
    params: {
      forMobile: true,
      language: locale.value as any,
    },
    pagination: {
      page: 0,
      perPage: 50,
      orderBy: [
        {
          fieldName: 'weight',
          sortOrder: 'DESC',
        },
      ],
    },
  },
})

const sectionDataT = {
  button: 'link-providers-se7s',
}
</script>

<style scoped>
.providers-list {
  gap: var(--spacing-150);
}

.m-providers-slider {
  .providers-list {
    padding: 0;
  }
}

.provider-card-item {
  flex-shrink: 0;

  &:first-child {
    margin-left: var(--spacing-200);
  }

  &:last-child {
    margin-right: var(--spacing-200);
  }
}
</style>
